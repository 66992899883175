import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { AccountInformation } from "@cpchem/azure-auth";
import { getUserTraits } from "@utilities/log/user-traits";
import { isInDevEnvironment } from "@utilities/log/in-dev-environment";
import config from "@config";

export function configureLogRocket(): void {
    const inDevEnv = isInDevEnvironment(config.env);
    if (!inDevEnv && config.logging.logRocketAppId !== "") {
        LogRocket.init(config.logging.logRocketAppId);
        setupLogRocketReact(LogRocket);
    }
}

export function setLogRocketUserId(userAcct: AccountInformation): void {
    LogRocket.identify(userAcct.username, getUserTraits(userAcct));
}
